// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'averil/averil';
html, body {
	height: 100%;
}
body {
	font-weight: 400;
	font-family: 'Lato';
}
h1 {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 200;
    text-align: left;
}
h2.form-group-title {
    margin: -5px;
    font-size: 1.3em;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
    color: #fff;
}

.alert {
    h1, h2, h3 {
        margin: 0 0 10px;
    }
}

.navbar-header {
    float: left;
}
.navbar-right {
    float: right;
    margin: 0 -15px;
	li {
	    float: left;
	}
	.navbar-text {
	    margin: 15px 5px;
	}
	&>li>a {
	    padding: 15px;
	}
}
.navbar {
    border-radius: 0!important;
}
.row.zero {
    margin: 0;
}


td .btn {
  margin-right: 5px; }

.nav-sub {
  margin-bottom: 10px; }
  .nav-sub li {
    color: #333;
    font-size: 12px; }
    .nav-sub li a {
      padding: 5px 15px;
      color: #848484; }

iframe#modal-frame {
  width: 100% !important;
  height: 100%;
  border: none;
  min-height: 800px;
  min-width: 600px; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 700px; } }

.table thead th {
  cursor: pointer; }
tr{
  position: relative;
}
tr input[type="checkbox"] {
    position: relative;
    width: auto;
}


.row.well .form-group {
    margin-bottom: 0!important;
}
.filter-presets {
    margin: 5px 0;
}
.row.well {
    margin: 0 0 15px;
    border: none;
}
table a {
    font-weight: 400;
}
.container.box-card {
    letter-spacing: 0.02em;
}
hr {
    //margin: 1em 0!important;
    background: #f3f3f3!important;
    border: none;
    height: 1px;
}
.button{
    font-weight: 600!important;
    letter-spacing: 0.02em;
}
.table>tbody>tr>td.rel {
    vertical-align:middle;
    .checkbox{
        position:relative;
        vertical-align:middle;
        input[type=checkbox]
        {
            margin-left:0;
        }
    }
}
.vmiddle{
    vertical-align:middle!important;
}
.nav>li>a {
    font-weight: 400;
}
.label + .label {
    margin: 2px;
    display: inline-block;
}

.import-status span {
    display: inline-block;
    padding: .4em .8em;
}

.import-status {
    margin-top: 5px;
}

a.details-toggle {
    font-size: 110%;
}

.import-list {
    margin-top: 10px;
}

.report-lists ul {
    padding: 10px;
    margin: 10px 0;
    font-size: 90%;
    font-weight: 400;
    overflow-y: scroll;
    height: 200px;
}

.report-lists ul li {
    padding: 2px 0;
}
.nav{
    &.nav-stacked{   
        > li > a{
            font-weight: 400;
            border-left: 3px solid transparent;
            &:focus, 
            &:hover {
                text-decoration: none;
                background-color: transparent;
                border-left-color: #04b38c;
            }
        }
    }
}
.form-control {
    border: 2px solid #ccc;
    font-weight: 600;
}
.form{
    margin-bottom:20px;
}


button,
input[type="submit"],
input[type="button"] {
    width: auto;
    min-width: 120px;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
    margin-left: 0;
}
.reports-card {
    background: #f4f4f4;
    padding: 2px;
    margin-bottom:30px;
    > a {
        color: inherit;
        padding: 10px 15px;
        display: block;
        &:hover,
        &:focus{
            text-decoration:none;
        }
        h3 {
            margin: 0;
            line-height: 1;
        }
    }
    table {
        margin: 0;
        > td {
            vertical-align: middle!important;
        }
    }
    .table td {
        vertical-align: middle;
        border: none;
    }
}
.is-lowercase{
    text-transform:lowercase;
}
.text-muted{
    color:#aaa;
}


.balance {
    padding: 10px 20px;
    background: #04b38c;
    margin: 12px 0 0;
    color: #fff;
    border-radius: 3px;
    text-align: right;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.22);
    p {
        font-weight: 400;
        font-size: 1.4em;
        margin: 0;
    }
    h3 {
        font-weight: 600;
        margin: 0;
        font-size: 2.4em;
        line-height: 1.3;
    }
    small {
        font-weight: 400;
    }
}

.headings {
    padding: 0 15px;
}
.tab-content .tab-pane >.row {
    margin-bottom: 20px;
}
a.is-close {
    font-size: 3em;
    line-height: 1;
    width: 40px;
    height: 40px;
    display: inline-block;
    float: right;
    text-align: center;
    color: inherit;
    &:hover,
    &:focus{
        text-decoration:none;
    }
}
a:hover{
    text-decoration:none!important;
}
input[type="file"] {
    display: block;
    border: 2px dashed #ccc;
    padding: 15px;
    -webkit-appearance: initial;
    color: #4d5d70;
    line-height: 1;
}
.reports-card table a {
    color: inherit;
}
.mce-btn button {
    min-height: 0;
    min-width: 0;
}

.input-group.search {
    margin-top: -6px;
    display: block;
    position: relative;
}

.input-group.search input {
    width: 100%;
    float: none;
    display: block;
    padding-right: 60px;
}

.input-group.search .button {
    width: auto;
    min-width: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    border: none;
    min-height: 42px;
    height: 42px;
}

.filter.badge{
    &:hover,
    &:focus{
        color:#888;
    }
    &.is-success{
        &:hover{
            color:#fff;
        }     
    }
}

.reports-card-header {
    padding: 10px;

    a:last-child {
        float: right;
        display: inline-block;
    }
    h3 {
        margin: 0;
    }

    a:first-child {
        display: inline-block;
        width: 75%;
    }
}