hr{
    margin: 2.33em 0;
    background: #ddd;
    border: none;
    height: 1px;
}

ol {
    list-style: decimal outside;
    margin: 1em 2em;
}
ul {
    list-style: disc outside;
    margin: 1em 2em;
}
ol,ul{
	&>li{
		display:list-item;
	}
}

.is-{
	&left{
		text-align:left;
	}
	&centered{
		text-align:center;
	}
	&right{
		text-align:right;
	}
	&justified{
		text-align:justify;
	}
	&uppercase{
		text-transform:uppercase;
	}	
	&lowercase{
		text-transform:lowercase;
	}
}

[disabled],
.is-disabled{
  cursor: disabled;
}

@each $name, $pair in $colors
{
	$color: nth($pair,1);
	$color-invert: nth($pair,2);
	
	.is-#{$name}
	{
		color: $color;
	}
}
.is-muted{
	color:$gray-light;
}

.is-fixed{
	position: fixed;
}
.is-fixed + .container {
    margin-top: 80px;
}

@keyframes spin-around {
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(359deg)
	}
}

.loader, 
.is-loading{
	position:relative;
	color: transparent!important;
    pointer-events: none;
	&:after {
	    animation: spin-around 600ms infinite linear;
	    border: 1px solid $gray-light;
		border-width: 2px 0 2px 2px;
		border-color: transparent transparent $gray-light $gray-light;
	    border-radius: 100%;
	    content: "";
	    display: block;
	    height: rem(20);
	    width: rem(20);
	    left: 50%;
	    top: 50%;
	    margin-left: -8px;
	    margin-top: -9px;
	    position: absolute;
	}
}