// Button sizes
@mixin button-size($padding-base, $font-size, $line-height, $border-radius) {
  padding: $padding-base;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
// Buttons
// --------------------------------------------------
// Base styles
.button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: rem(2) solid transparent;
  white-space: nowrap;
  @include button-size($padding-base, $font-size-base, $line-height-base, $border-radius-base);
  @include user-select(none);
  + .button{
    margin-left:5px;
  }
  &,
  &:active{
    &:focus{
      @include tab-focus;
    }
  }

  @each $name, $pair in $colors
  {
    $color: nth($pair,1);
    $color-invert: nth($pair,2);
    @if $name == "default"{
      background: transparent;
      border-color: lighten($color, 70%);
      color: lighten($color, 30%);

      &:hover,
      &:focus{
        border-color: lighten($color, 50%);
        color: lighten($color, 30%);
        text-decoration: none;
      }
      &:active{
        //border-color: transparent;
        outline: 0;
      }      
      &.is-outlined{
        background: transparent!important;
        border-color: lighten($color, 60%);
        color: $color;
        &:hover,
        &:focus{
            border-color: lighten($color, 30%);
            color: $color;
        }
        &.is-loading:after{
          border-color: transparent transparent $color-invert $color-invert !important;
        }
      }
    }@else{
      &.is-#{$name}
      {
        background: $color;
        border-color: transparent;
        color: $color-invert;
        &:hover,
        &:focus{
          background: darken($color, 10%);
          border-color: transparent;
          color: $color-invert;
        }
        &:active{
          border-color: transparent;
        }      
        &.is-outlined{
          border-color: $color;
          color: $color;
          &:hover,
          &:focus{
              border-color: darken($color, 10%);
              color: darken($color, 10%);
          }
        }
        &.is-loading:after{
          border-color: transparent transparent $color-invert $color-invert !important;
        }  
      }
    }
  }
  &.is-large {
    @include button-size($padding-large, $font-size-large, $line-height-large, $border-radius-large);
  }
  &.is-small {
    @include button-size($padding-small, $font-size-small, $line-height-small, $border-radius-small);
  }

  &.is-block {
    display: block;
    width: 100%;
  }

  &.disabled,
  &[disabled]{
    cursor: $cursor-disabled;
    opacity: 0.5;
  }
}
