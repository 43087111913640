// Alerts Element
@mixin alert-variant($color,$background: #f6f6f6) {
	color: $color;
	background-color: $background;
}

.alert{
	padding: rem(16) rem(20);
	font-size: rem(14);
	line-height: $line-height-base;
	border-radius:$border-radius-base;
	font-weight:normal;
	margin-bottom: 2rem;
	@include alert-variant($gray);
	.title, h1, h2, h3 {
	    font-size: rem(20);
	    margin: 0 0 rem(10);
	}
}
// Badges Element
.badge {
	display: inline-block;
	padding: rem(4) rem(10);
	background: $gray-lighter;
	color:$gray;
	font-size: rem(12);
	font-weight: 600;
	line-height: rem(14);
	border-radius:rem(2);
	text-align: center;
	vertical-align: middle;
	+ .badge{
		margin-left:5px;
	}
	.is-close{
		width:rem(14);
		height:rem(14);
		border-radius:rem(14);
	    display: inline-block;
	    font-size: 1.8rem;
	    line-height: 1.2rem;
	    float: right;
	    margin: 0 -5px 0 5px;
	    background: rgba(0, 0, 0, 0.1);
		&:after{
			content:"×";
		}
	}
}

.alert,
.badge{
	@each $name, $pair in $colors
	{
		$color: nth($pair,1);
		$color-invert: nth($pair,2);

		@if ($name != "default") {
		  &.is-#{$name}
		  {
		    @include alert-variant($color-invert,$color);
		  }
		}
	}
}